import React, {useEffect, useState} from "react"
import {Container,Button,Navbar,Nav} from 'react-bootstrap';
import { Link, navigate } from "gatsby"
import VideoImg1 from "../../assests/images/video1.jpg"; 
import NavLink from  "../../components/NavLink/Custom_Presentation"; 
import Header from  "../../components/Common/Header"; 

import {config} from '../../components/Common/constant';

import HTMLReactParser from 'html-react-parser';
import { GET_ARTICLE } from "../../queries/common_use_query";

const PresentationPage = () => {

    const [ article_data, setArticleData ] = useState('');
    const { loading, error, data } = GET_ARTICLE('sell-your-home-with-property-launch');

    useEffect(()=>{
        setArticleData(data && data.articles[0]);
    }, [data]);

    var strapi_url = process.env.GATSBY_STRAPI_SRC;
    
    return <div className="pre-wrapper">
      
      <Header />


      <div className="contact-wrapper intro_section list_my_property">
          <Container> 
                       
            <div className="contact-wrap">
                <div className={"title_nav"}>
                    <Link to={config.custom_compare_cost} className="left-icon" title="Previous">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.1785 0.678711L14.9463 2.44648L7.70517 9.6876L14.9463 16.9287L13.1785 18.6965L4.16964 9.6876L13.1785 0.678711Z" fill="#67707B"/>
                        </svg>
                    </Link>
                    <Link to={config.custom_contract_form} className="right-icon" title="Next">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.82148 18.6963L5.05371 16.9285L12.2948 9.68741L5.05371 2.44629L6.82148 0.678522L15.8304 9.68741L6.82148 18.6963Z" fill="#67707B"/>
                        </svg>
                    </Link>
                </div>

                <div className="contact-content">
                    <h2>{article_data && article_data.Modules[0].Title}</h2>
                    <p class="fig-content">{article_data && HTMLReactParser(article_data.Modules[0].Content)}</p>

                    {article_data && article_data.Modules[0].CTA_1_Label && <Link to={article_data.Modules[0].CTA_1_URL ? article_data.Modules[0].CTA_1_URL : '#'} className="btn btn-primary">{article_data.Modules[0].CTA_1_Label}</Link> }

                    {article_data && article_data.Modules[0].CTA_2_Label && <Link to={article_data.Modules[0].CTA_2_URL ? article_data.Modules[0].CTA_2_URL : '#'} className="btn btn-secondary">{article_data.Modules[0].CTA_2_Label}</Link> }
                </div>
                {/* contact-content */} 

                <div className="employment-right">
                    <figure>
                        { article_data && article_data.Modules[0].Upload_Image && <img src={strapi_url+''+article_data.Modules[0].Upload_Image.url} alt="" /> }
                    </figure>

                    <div className="content-block">
                        <h3>Amelia Adams</h3>
                        <Link to="#">07891 219 384</Link>
                    </div>
                </div>
                {/* video-wrap */}
            </div>
            {/* contact-wrap */}
          </Container>                     
      </div>
      {/* contact-wrapper */}
      
      <NavLink tab_name="Fees" prevLink={config.custom_comparables} nextLink={config.custom_contract_form} />

    </div>
    // pre-wrapper
}

export default PresentationPage